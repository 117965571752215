import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../templates/layout';
import ContactWrapper from '../styles/contact/ContactStyles';

const FormSuccess = ({ path }) => {
  const seo = {
    page: 'contact',
    title: 'お問い合わせ',
    description:
      // eslint-disable-next-line quotes
      "Let's chat! I'm open to lots of new opportunities, freelance or a fulltime position.",
    url: 'https://about.9ualia.com/form-success',
    // imgUrl: `${data.pageImg.publicURL}`,
    // imgAlt:
    //   'jdcastro logo, twitter, instagram, facebook, github icons with @9ualia username',
    breadcrumbs: [
      {
        name: 'Contact',
        path: '/contact',
      },
    ],
  };

  return (
    <Layout seo={seo} path={path}>
      <ContactWrapper>
        <h1>お問い合わせ</h1>
        <h2>お問い合わせをお受けしました</h2>
        <h3>ご返信まで2-3営業日お待ちくださいませ。</h3>
        <Link to="/">戻る</Link>
      </ContactWrapper>
    </Layout>
  );
};

FormSuccess.propTypes = {
  path: PropTypes.string.isRequired,
};

export default FormSuccess;
